<template>
  <div id="start">
    <div class="first">
      <ul class="navBar">
        <li class="navItem">
          <button @click="handleClick()">
            <img src="./assets/logo-fadas.png">
          </button>
        </li>
        <li class="navItemR" style="padding-right: 80px;padding-left: 20px">
          <a href="#screenshots"><h3>Bilder</h3></a>
        </li>
        <li class="navItemR" style="padding-right: 20px;padding-left: 20px">
          <a href="#kontakt"><h3>Kontakt</h3></a>
        </li>
        <li class="navItemR" style="padding-right: 20px;padding-left: 20px">
          <a href="#gruende"><h3>Grüde fürs FADAS</h3></a>
        </li>
        <li class="navItemR" style="padding-right: 20px;padding-left: 20px">
          <a href="#uberblick"><h3>Überblick</h3></a>
        </li>
        <li class="navItemR" style="padding-right: 20px;padding-left: 20px">
          <a href="#start"><h3>Start</h3></a>
        </li>

        <li class="navItemSmall">
          <a href="#start"><h3>Start</h3></a>
        </li>
        <li class="navItemSmall">
          <a href="#uberblick"><h3>Überblick</h3></a>
        </li>
        <li class="navItemSmall">
          <a href="#gruende"><h3>Grüde fürs FADAS</h3></a>
        </li>
        <li class="navItemSmall">
          <a href="#kontakt"><h3>Kontakt</h3></a>
        </li>
        <li class="navItemSmall">
          <a href="#screenshots"><h3>Bilder</h3></a>
        </li>
      </ul>
    </div>
    <div :style="'background-image: url('+require('./assets/hg.png')+')'" style="width: 100%;background-position: top;background-repeat: no-repeat">
      <div style="backdrop-filter: blur(3px)">
        <table class="headerTab">
          <tr>
            <td style="width: 70%">
              <h1 style="font-size: xxx-large">FADAS</h1>
              <h3 style="font-size: xx-large">DIE Software zur Datenverwaltung von Hufschmieden</h3>
            </td>
            <td style="width: 30%">
              <img class="logo" alt="FADAS logo" src="./assets/logo.png">
            </td>
          </tr>
        </table>
      </div>
    </div>
    <hr>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <hr>
    <div class="hello" id="screenshots">
      <h1 style="text-align: start;padding-bottom: 1rem">
        Beispielbilder
      </h1>
      <p style="text-align: start;padding-bottom: 1rem">Alle dargestellten Daten sind beispielhaft und entsprechen keinen realen Kundendaten.
        Alle Ähnlichkeiten mit natürlichen Personen sind zufällig.</p>
      <h3 style="text-align: start">Kalender mit Terminen</h3>
      <p style="text-align: start">- farbliche Markierung für unterschiedliche Mitarbeiter</p>
      <img class="bild" alt="Kalender" src="./assets/kalender.png">
      <h3 style="text-align: start">Kundenliste</h3>
      <img class="bild" alt="Kalender" src="./assets/kunden.png" width="902px">
      <h3 style="text-align: start">Detaillierte Kundeninformationen</h3>
      <img class="bild" alt="Kalender" src="./assets/kunde_info.png" width="902px">
      <h3 style="text-align: start">Terminliste</h3>
      <p style="text-align: start">- farbliche Markierung für unterschiedliche Mitarbeiter</p>
      <img class="bild" alt="Kalender" src="./assets/nicht_bezahlt.png" width="902px">
      <h3 style="text-align: start">Beispielrechnung</h3>
      <img class="bild" alt="Kalender" src="./assets/rechnung.png" width="902px">
      <h3 style="text-align: start">Detaillierte Informationen über das Pferd eines Kunden</h3>
      <img class="bild" alt="Kalender" src="./assets/pferd_info.png" width="902px">
      <h3 style="text-align: start">Statistik der Einnahmen und Ausgaben</h3>
      <img class="bild" alt="Kalender" src="./assets/statistik.png" width="902px">
      <h3 style="text-align: start">Ausgabenliste</h3>
      <img class="bild" alt="Kalender" src="./assets/ausgaben.png" width="902px">

      <p style="padding-top: 0.5rem;padding-bottom: 5rem;text-align: start">Bei Interesse melden Sie sich über die <a href="#kontakt" style="text-decoration: underline">Kontaktdaten</a>.</p>
    </div>
    <hr>
    <p>Impressum:<br>
    Max Freiberg<br>
      Ploßstraße 3, 04347 Leipzig, Deutschland <br>
    Kontakt: <br>
    Tel.: 01781432411<br>
    Mail: max.freiberg@freenet.de</p>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  methods: {
    handleClick(){
      this.int=this.int+1;
      if (this.showNav==='visible'){
        this.showNav='collapse'
        this.navHeigth='0'
        this.headerMargin= '50px'
      }else {
        this.showNav='visible'
        this.navHeigth='50px'
        this.headerMargin= '250px'
      }
    }
  },
  data(){
    return{
      int: 1,
      showNav: 'visible',
      navHeigth: '50px',
      headerMargin: '250px'
    }
  }
}
</script>

<style>
* { margin: 0 !important; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: block;
  top: 0
}
button{
  background-color: transparent;
  border-color: transparent;
}
.first{
  padding-bottom: 50px;
  position: relative;
  z-index: 999
}
.headerTab{
  width: 825px;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 640px
}
.navItem{
  margin-top: auto !important;
  margin-bottom: auto !important;
  float: left;
  display: grid;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
  height: 50px;
  align-content: center;
}
.navItemR{
  margin-top: auto !important;
  margin-bottom: auto !important;
  float: right;
  display: grid;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
  height: 50px;
  align-content: center;
}
.navItemSmall{
  margin-top: auto !important;
  margin-bottom: auto !important;
  display: grid;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  height: 0;
  align-content: center;
  visibility: collapse;
}
.bild{
  padding-bottom: 2rem;
  height: 100%;
  width: 902px;
  object-fit: contain
}
.logo{
  height: 300px;
  width: 100%;
  object-fit: contain
}
.hello{
  width: 830px;
  margin-right: auto !important;
  margin-left: auto !important;
  align-content: start;
  display: block;
}
div{
  scroll-margin-top: 60px;
}
.navItem:hover, .navItemR:hover, .navItemSmall:hover{
  background-color: #696969;
}
@media only screen and (max-width: 850px) {
  .headerTab{
    width: 600px;
  }
  .navItem{
    float: none;
  }
  div{
    scroll-margin-top: 110px;
  }
}
@media only screen and (max-width: 700px) {
  .logo{
    height: 100%;
  }
  .navItemSmall{
    margin-top: auto !important;
    margin-bottom: auto !important;
    display: grid;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0;
    height: v-bind('navHeigth');
    align-content: center;
    visibility: v-bind('showNav');
  }

  .headerTab{
    height: 200px;
    width: 250px;
    margin-top: v-bind('headerMargin') !important;
  }

  ul{
    padding: 0;
  }

  .navItemR{
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    height: 0;
    visibility: collapse;
  }

  .bild{
    width: 250px;
    display: block;
  }

  div{
    scroll-margin-top: 310px;
  }

  .hello{
    width: 80%;
  }
}
.navBar{
  list-style-type: none;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: gray;
}
body{
  top: 0;
  position: relative;
  margin: 0;
  display: block;
}
hr{
  border-width: 4px;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
}
a{
  color: #013d0e;
  text-decoration: none;
}
</style>
